<template>
  <el-breadcrumb v-if="list && list.length" separator-class="el-icon-arrow-right"
    class="bread"
  >
    <el-breadcrumb-item v-for="item in list" :key="item.path" :to="item.path">
      {{item.name}}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.bread {
  margin-bottom: 20px;
  line-height: 1.2;
}
</style>
