<template>
  <div class="progress-wrap">
    <div class="progress-now" :style="{width: Math.min(100, Math.round(100*value))+'%'}"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="less" scoped>
.progress-wrap {
  height: 30px;
  width: 100%;
  border: 4px solid rgb(208, 208, 208);

  .progress-now {
    height: 100%;
    background-color: rgb(251, 204, 166);;
  }
}
</style>
