<template>
  <div class="subject" :class="{'page-is-loading': pageIsLoading}">
    <Bread :list="bread"></Bread>
    <div class="subject-info">
      <div class="subject-info-title">
        <span :class="{'app-must-flag-red': subjectInfo.mustFlag === '1','app-must-flag-blue': subjectInfo.mustFlag === '0'}">
          <span>{{subjectInfo.mustFlag === '1' ? '必須': '任意'}}</span></span>
        <span>{{subjectInfo.title}}</span>      
      </div>
      <div class="subject-info-wrap">
        <div class="subject-info-left">
          <el-image class="subject-info-img" :src="subjectInfo.pic" alt="コース画像" fit="contain">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="subject-info-right">
<!--          <div class="subject-info-item">-->
<!--            <div class="subject-info-item-label">講座</div>-->
<!--            <div class="subject-info-item-value">{{subjectInfo.courseName}}</div>-->
<!--          </div>-->
          <!-- <div class="subject-info-item">
            <div class="subject-info-item-label">教科カテゴリ</div>
            <div class="subject-info-item-value">{{subjectInfo.category}}</div>
          </div>
          <div class="subject-info-item">
            <div class="subject-info-item-label">教科タグ</div>
            <div class="subject-info-item-value">{{subjectInfo.tags.join(',')}}</div>
          </div>
          <div class="subject-info-item">
            <div class="subject-info-item-label">教科ステータス</div>
            <div class="subject-info-item-value">{{subjectInfo.status}}</div>
          </div>
          <div class="subject-info-item">
            <div class="subject-info-item-label">承認要否</div>
            <div class="subject-info-item-value">申請承認{{subjectInfo.needApprove?'':'不'}}要</div>
          </div>
          <div class="subject-info-desc">
              {{subjectInfo.abstract}}
          </div>
          -->
          <div class="subject-info-item">
            <div style="font-size: 17px;padding: 8px;;white-space:pre-wrap; word-wrap:break-word;line-height:1.5em" v-html="subjectInfo.abstract"></div>
          </div>
          <div class="subject-info-desc" v-if="subjectInfo.subjectDetail">
              {{subjectInfo.subjectDetail}}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="subject-apply">{{isLogin?'受講申請はこちらへ。':'ログインして受講申請ください。'}}</div> -->
    <div class="subject-detail">
      <!-- <div style="margin-bottom:10px"><strong style="font-size: 17px;white-space:pre-wrap; word-wrap:break-word;line-height:1.5em">{{subjectInfo.subjectDetail}}</strong></div> -->
      <!-- <div class="subject-detail-item"
        v-for="(item, index) in subjectInfo.detail"
        :key="index"
      >
        <div class="subject-detail-title">{{item.name}}</div>
        <div class="subject-detail-content">
          <div class="subject-detail-content-item-wrap" v-for="(i, idx) in item.value" :key="idx">
            <div class="subject-detail-content-item" v-if="typeof i === 'string'">
              · {{i}}
            </div>
            <div v-else class="subject-detail-content-item">
              <div class="subject-detail-content-item-title">
                {{`${idx+1}. ${i.name}`}}
              </div>
              <div class="subject-detail-content-item-value">
                {{i.value}}
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="subject-detail-item">
        <div class="subject-detail-title">単元一覧
          <div style="float:right">
            <el-button native-type="button" type="warning" @click="markNotice()">！マークの説明</el-button>
          </div>
        </div>
        <div class="subject-detail-content">
          <div class="app-list-sorter-wrap clearfix">
<!--            <el-alert-->
<!--              :title="'受講可能な講座は'+this.listTotal+'件です。'"-->
<!--              type="success"-->
<!--              :closable="false"-->
<!--            >-->
            <!-- </el-alert> -->
            <!-- <div class="app-list-sorter-page" style="text-align: right;margin-top:10px;">
              <span style="font-weight:bold;">

              </span>
            </div> -->
            <!-- <div class="app-list-sorter-page">
              <span style="font-weight:bold">{{this.listTotal}}</span>{{pageText}}
            </div>
            <div class="app-list-sorter-right">
              <span>並び替え</span>
              <el-select v-model="sortBy" size="medium" style="width: 110px">
                <el-option
                  v-for="item in sortByOptions"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </div> -->
          </div>
          <div v-loading="tableIsLoading">
            <div class="app-list-wrap">
              <div class="app-list" v-for="(item, index) in unitList" :key="index">
                <div class="app-list-left">
                  <el-image class="app-list-left-img" :src="item.pic"
                    alt="コース画像" fit="contain"
                    @click="item.studyStatus === '1' && goUnit(item.id)"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>

                <div class="app-list-right" v-if="item.mustFlag === '0' || item.studyStatus !== '0'">
                  <div>
                    <div class="app-list-right-title"  >
                      <span :class="{'app-must-flag-red': item.mustFlag === '1','app-must-flag-blue': item.mustFlag === '0'}">
                        <span>{{item.mustFlag === '1' ? '必須': '任意'}}</span></span>
                      <strong @click="item.studyStatus === '1' && goUnit(item.id)">{{item.title}}</strong>
                      <strong style="color:red">{{item.studyStatus == '0'?'（前の単元を受講した後で、この単元を受講してください。）':''}}</strong>
                    </div>
                    <div class="app-list-right-desc"><strong style="font-size:17px;white-space:pre-wrap; word-wrap:break-word;line-height:1.5em">{{item.desc}}</strong></div>
                    <!-- <div class="app-list-right-desc"><strong style="white-space:pre-wrap; word-wrap:break-word;line-height:3.5em;color:red">教材受講進捗: {{item.materiaStudyNum}}件/{{item.materiaNum}}件中、受講完了</strong></div> -->
                    
                    <div class="app-list-right-progress" v-if="item!==null && item.mustFlag === '1'">
                      <div class="app-list-right-progress-text">
                        <span class="app-list-right-progress-title"><span>教材受講進捗（必須）</span></span>
                        <span class="app-list-right-progress-detail">{{item.materiaNum> 0 ? Math.round(100 * item.materiaStudyNum / item.materiaNum)+ '%':'100%'}}</span>               
                        <span class="app-list-right-progress-detail">({{item.materiaStudyNum}}件/{{item.materiaNum}}件中)</span>
                      </div>
                      <div class="app-list-right-progress-bar">
                        <Progress :value="item.materiaStudyNum / item.materiaNum" />
                      </div>
                    </div>
                    <div class="app-list-right-progress">
                      <div class="app-list-right-progress-text">
                        <span class="app-list-right-progress-title"><span>テスト（必須）</span></span>
                        <span class="app-list-right-progress-detail" :style="{
                          color: item.testExistFlg === '0' && item.testPassFlg === '1' ? 'red' : 'black'
                        }">
                          <!-- {{Math.round(100 * item.materiaStudyNum / item.materiaNum)}}% -->
                          {{item.testExistFlg === '0' ? (item.testPassFlg === '0' ? '完了': '未完了') : 'なし'}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="app-list-right" style="background-color:gray;" v-if="!(item.mustFlag === '0' || item.studyStatus !== '0')">
                  <div>
                    <div class="app-list-right-title-no-hover"  >
                      <span :class="{'app-must-flag-red': item.mustFlag === '1','app-must-flag-blue': item.mustFlag === '0'}">
                        <span>{{item.mustFlag === '1' ? '必須': '任意'}}</span></span>
                      <strong @click="item.studyStatus === '1' && goUnit(item.id)">{{item.title}}</strong>

                    </div>
                    <div class="app-list-right-desc"><strong style="font-size:17px;white-space:pre-wrap; word-wrap:break-word;line-height:1.5em">{{item.desc}}</strong></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- <el-pagination
            background
            :page-size="listSize"
            :pager-count="7"
            layout="prev, pager, next"
            :total="listTotal"
            @current-change="pageChange"
          >
          </el-pagination> -->
        </div>
      </div>
    </div>
    <!-- <hr> -->
    <el-button native-type="button" class="studypack-button-primary" type="primary" plain @click="goBack"> 教科一覧に戻る </el-button>

  </div>
</template>

<script>
import Bread from '@/components/Bread.vue';
import request from '@/utils/request';
import Progress from '@/components/Progress.vue';
import loadingMixin from '@/utils/loadingMixin';
import { Notification } from 'element-ui';

export default {
  name: 'Subject',
  components: {
    Bread,
    Progress,
  },
  // inject: ['isLogin'],
  mixins: [loadingMixin],
  data() {
    return {
      bread: [],
      tableIsLoading: false,
      subjectID: '',
      subjectInfo: {
        courseName: '',
        title: '',
        pic: '',
        abstract: '',
        detail: '',
        // detail: [
        //   {
        //     name: '教科説明００１',
        //     value: [
        //       '例文：論理的思考の重要性、構成されている要素を理解できます',
        //       '「OJT（on-the-job training）」とは何かが分かる',
        //       'OJT研修の手順を理解できる',
        //     ],
        //   },
        // ],
      },
      listTotal: 0,
      listPage: 1,
      listSize: 20,
      sortBy: '0',
      sortByOptions: [
        {
          key: '0',
          value: '新着順',
        },
        {
          key: '1',
          value: '人気順',
        },
        {
          key: '2',
          value: '評価順',
        },
      ],
      unitList: [],
    };
  },
  computed: {
    pageText() {
      const pageStart = (this.listSize * this.listPage) + 1;
      const pageEnd = this.listSize * (this.listPage + 1);
      return ` 件中 ${pageStart}～${pageEnd} 件を表示`;
    },
  },
  created() {
    this.subjectID = this.$route.params.id;
    request('getSubjectDetail', {
      courseId: this.$route.params.courseId,
      subjectId: this.subjectID,
    }).then((res) => {
      this.loadingClose();
      this.subjectInfo = {
        courseName: res.courseTitle,
        title: res.subjectTitle,
        pic: res.subjectImagePath,
        abstract: res.subjectOutline,
        subjectDetail: res.subjectDetail,
        mustFlag: res.mustFlag
      };
      const { count, studentUnitResponseResourceList: list } = res.studentUnitResponseResourceList;

      this.listTotal = count;
      this.unitList = list.map((item) => ({
        id: item.unitId,
        pic: item.unitImagePath,
        title: item.unitName,
        desc: item.unitOutline,
        testExistFlg: item.testExistFlg,
        testPassFlg: item.testPassFlg,
        materiaNum: item.materiaNum,
        materiaStudyNum: item.materiaStudyNum,
        studyStatus: item.studyStatus,
        mustFlag: item.mustFlag
      }));
      this.bread = [
        {
          path: '/',
          name: 'トップページ',
        },
        {
          path: `/course/${this.$route.params.courseId}`,
          name: this.subjectInfo.courseName,
        },
        {
          name: this.subjectInfo.title,
        },
      ];
    });
  },
  methods: {
    // getUnitList(data) {
    //   this.tableIsLoading = true;
    //   request('getUnitList', data).then((res) => {
    //     this.unitList = res;
    //   }).finally(() => {
    //     this.tableIsLoading = false;
    //   });
    // },
    // pageChange() {
    //   this.getUnitList();
    // },
    goUnit(id) {
      this.$router.push({
        path: `/unit/${id}/${this.$route.params.courseId}/${this.subjectID}`,
      }).catch(() => {});
    },
    goBack() {
      this.$router.push({
        path: `/course/${this.$route.params.courseId}`,
      }).catch(() => {});
    },
    markNotice(){
      Notification.closeAll()
      Notification({
        title: 'マーク説明',
        dangerouslyUseHTMLString: true,
        message: '<span class="app-must-flag-red"><span>必須</span></span>かならず受講完了する必要がある単元<p><span class="app-must-flag-blue"><span>任意</span></span>任意で受講すればよい単元',
        offset: 75,
      });
    }
  },
  mounted() {
    // this.getUnitList();
  },
};
</script>

<style lang="less">
.subject {
  margin: 30px 0;

  .subject-info {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .subject-info-title {
      text-align: center;
      font-size: 26px;
      margin-bottom: 60px;
      line-height:1.5em;
    }
    .subject-info-wrap {
      display: flex;

      .subject-info-left {
        margin-right: 20px;
        box-sizing: border-box;
        // border: 1px solid #ccc;

        .subject-info-img {
          width: 320px;
          min-height: 230px;
          // height: 282px;
          .image-slot {
            min-height: 230px;
          }
        }
      }
      .subject-info-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        // border: 1px solid #ccc;
        box-sizing: border-box;
        justify-content: space-around;

        .subject-info-item {
          font-size: 17px;
          // border-bottom: 1px solid #ccc;

          .subject-info-item-label {
            padding: 8px;
            width: 180px;
            display: inline-block;
            // background-color: #eee;
          }
          .subject-info-item-value {
            padding: 8px;
            display: inline-block;
          }
        }
        .subject-info-desc {
          // flex: 1;
          padding: 8px;
          font-size: 15px;
          white-space:pre-wrap;
          word-wrap:break-word;
          line-height:1.5em;
          background-color: rgba(251, 204, 166);
        }
      }
    }
  }
  .subject-apply {
    background-color: #F1DCDB;
    padding: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .subject-detail {
    .subject-detail-item {
      margin-bottom: 20px;

      .subject-detail-title {
        // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        font-size: 18px;
        font-weight: bold;
        // padding-bottom: 10px;
        margin-left: 10px;
      }
      .subject-detail-content {
        font-size: 15px;
        // padding: 10px 0;

        .subject-detail-content-item-wrap {
          .subject-detail-content-item {
            margin-bottom: 15px;

            .subject-detail-content-item-title {
              margin-bottom: 8px;
            }
            .subject-detail-content-item-value {
              font-size: 14px;
              color: #777;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .subject {
    .subject-info {
      .subject-info-wrap {
        flex-direction: column;
        .subject-info-left {
          margin-right: 0;
          text-align: center;
          .subject-info-img {
            height: 160px;
          }
        }
        .subject-info-right {
          .subject-info-item {
            .subject-info-item-label {
              width: 80px
            }
          }
        }
      }
    }
  }
}
</style>
