import { Loading } from 'element-ui';

const loadingTime = 500;

const loadingMixin = {
  data() {
    return {
      pageIsLoading: true,
      loadingInstance: Loading.service({ fullscreen: true, background: '#fff' }),
      loadingTimePromise: new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, loadingTime);
      })
    }
  },
  beforeCreate() {
    if (!this.loadingInstance.visible) {
      this.loadingInstance = Loading.service({ fullscreen: true, background: '#fff' });
    }
  },
  methods: {
    loadingClose() {
      this.loadingTimePromise.then(() => {
        this.pageIsLoading = false;
        this.loadingInstance.close();
      })
    }
  }
}

export default loadingMixin;
